import styled from 'styled-components'
import { colors, flexColumn } from '../variables'

export const AvisoCont = styled.div`
background: #F8F8F8;
padding-bottom: 50px;
  ${flexColumn}
  .index{
    ${flexColumn}
    height: calc(100vh - 55px);
    max-width:40%;
    position:fixed;
    top:55px;
    background:#F8F8F8;
    padding:60px 20px 20px 10vw;
    z-index:0;
    button{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 190.9%;
      text-decoration:none;
      color: ${colors.richBlack};
      margin-bottom: 20px;
      background:transparent;
      text-align:left;
      border:none;
      cursor: pointer;
      &:focus{
        outline:none;
      }
    }
    .on{
      color: #00AAAB;
    }
  }
  .content{
    width: 60%;
    margin-left:auto;
    padding:20px 10vw 20px 20px ;
    h1{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      margin: 30px 0 0 0 ; 
    }
    .text{
      padding-top:55px;
      h3{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 141.2%;
        color: #00AAAB;
        margin-bottom:15px;
      }
      p{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 141.2%;
        color: ${colors.richBlack};
      }
      ul{
        margin-bottom:15px;
        li{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 141.2%;
          margin-left:40px;
        }
      }
    }
  }
  @media (max-width:768px){
    .index{
      display:none;
    }
    .content{
      width:100%;
      padding:0 5%;
    }
  }
`;