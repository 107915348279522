import React, { useRef, useState } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { window } from 'browser-monads'

import Seo from "../components/seo";
import Layout from "../components/layout";

import { AvisoCont } from '../styles/components/aviso.styled'

const Aviso = () => {
  const [position, setPosition] = useState(0);

  const height = window.innerHeight / 2;
  useScrollPosition(({ currPos }) => setPosition(currPos.y));
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const textRef4 = useRef(null);
  const textRef5 = useRef(null);
  const textRef6 = useRef(null);
  const textRef7 = useRef(null);

  const textScroll1 = textRef1.current ? textRef1.current.offsetTop : 200; 
  const textScroll2 = textRef1.current ? textRef2.current.offsetTop : 200; 
  const textScroll3 = textRef1.current ? textRef3.current.offsetTop : 200; 
  const textScroll4 = textRef1.current ? textRef4.current.offsetTop : 200; 
  const textScroll5 = textRef1.current ? textRef5.current.offsetTop : 200; 
  // const textScroll6 = textRef1.current ? textRef6.current.offsetTop : 200; 
  // const textScroll7 = textRef1.current ? textRef7.current.offsetTop : 200; 

  console.log(height);
  return(
    <Layout>
      <Seo title="Aviso"/>
      <AvisoCont>
        <div className='index'>
          <button onClick={() => scrollTo('#text1')} className={position < -textScroll1 && 'on'}>RESPONSABLE DE LOS DATOS PERSONALES.</button>
          <button onClick={() => scrollTo('#text2')} className={position < -textScroll2 && 'on'}>FINALIDAD DEL TRATAMIENTO.</button>
          <button onClick={() => scrollTo('#text3')} className={position < -textScroll3 && 'on'}>DATOS PERSONALES A RECABAR.</button>
          <button onClick={() => scrollTo('#text4')} className={position < -textScroll4 && 'on'}>MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES. </button>
          <button onClick={() => scrollTo('#text5')} className={position < -textScroll5 && 'on'}>EXCLUSIÓN DE RESPONSABILIDAD DEL SITIO.</button>
          <button onClick={() => scrollTo('#text6')} className={position < -textScroll5 && 'on'}>TRANSFERENCIA DE DATOS PERSONALES.</button>
          <button onClick={() => scrollTo('#text7')} className={position < -textScroll5 && 'on'}>CAMBIOS AL AVISO DE PRIVACIDAD.</button>
        </div>
        <div className='content'>
          <h1>Aviso de privacidad</h1>
          <div className='text' id='text1' ref={textRef1}>
            <h3>RESPONSABLE DE LOS DATOS PERSONALES.</h3>
            <p>“Oblekcomp, S.A.P.I. de C.V.” (en adelante “Oblek”) con domicilio en Calle Cadetes del 47 número 6, Piso 1 departamento 2, Colonia San Miguel Chapultepec II Sección, Alcaldía Miguel Hidalgo, código postal 11850, Ciudad de México, tiene la encomienda de proteger la información personal proporcionada por sus usuarios (en adelante “Datos Personales”) y es el responsable de su Tratamiento (término que se define más adelante) cuando sean recabados a través del sitio de Internet www.myoblek.com o de la aplicación app.myoblek.com (en adelante el “Sitio”), medios impresos y/o vía telefónica (en adelante “Otros Medios”).</p>
          </div>
          <div className='text' id='text2' ref={textRef2}>
            <h3>FINALIDAD DEL TRATAMIENTO.</h3>
            <p>Oblek podrá solicitar y/o recabar a través del Sitio y Otros Medios, Datos Personales de los usuarios para: (i) Contactar con el usuario; (ii) promoción, tal como campañas de marketing; (iv) interacción con redes sociales para mejora de servicio; (v) Bases de datos; (vii) creación de contenidos; (viii) ofertas comerciales; y (ix) medición de funcionamiento de la plataforma y Sitio; así  como para dar cumplimiento con disposiciones legales que así lo requieran, o bien, cuando sea solicitado por autoridades competentes (en adelante “Tratamiento”). Oblek y/o cualquier tercero que llegue a intervenir en cualquier fase del Tratamiento de Datos Personales, guardará confidencialidad respecto de los mismos cuando tengan dicho carácter, conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos (en adelante “México”).</p>
          </div>
          <div className='text' id='text3' ref={textRef3}>
            <h3>DATOS PERSONALES A RECABAR.</h3>
            <ul>
              <li>Nombre completo</li>
              <li>Dirección- Teléfono</li>
              <li>Correo electrónico</li>
              <li>Datos fiscales</li>
              <li>Fecha de nacimiento</li>
              <li>Género</li>
              <li>Ocupación</li>
              <li>Empleador (en caso de ser aplicable para nuestros productos corporativos)</li>
            </ul>
            <p>Ustéd no podrá acceder a los servicios para los que se requieren sus Datos Personales cuando estos no sean proporcionados, sin embargo, sí podrá tener acceso a los demás Servicios que no los requieran.</p>
          </div>
          <div className='text' id='text4' ref={textRef4}>
            <h3>MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES. </h3>
            <p>Oblek cuenta con medidas físicas, técnicas y administrativas de seguridad para la protección de Datos Personales proporcionados por los usuarios. Oblek tomará las acciones, medidas y previsiones especiales que considere necesarias, a fin de salvaguardar el derecho a la protección de Datos Personales de menores de edad e incapaces. Si usted desea dejar de recibir mensajes promocionales vía correo electrónico y/o de forma impresa y/o vía telefónica por parte de Oblek puede solicitarlo por conducto de: hola@myoblek.com</p>
          </div>
          <div className='text' id='text5' ref={textRef5}>
            <h3>EXCLUSIÓN DE RESPONSABILIDAD DEL SITIO.</h3>
            <p>El Sitio podría contener hipervínculos o hipertextos “links”, banners, botones y/o herramientas de búsquedas en la “World Wide Web” que al ser utilizados por los usuarios transportan a otros portales o sitios de Internet que podrían ser propiedad de terceros. Los Datos Personales que los usuarios llegaren a proporcionar a través de estos portales o sitios de Internet no se encuentran contemplados por este Aviso de Privacidad y su Tratamiento no es responsabilidad de Oblek. Recomendamos a los usuarios verificar los Avisos de Privacidad desplegados y aplicables a estos portales y sitios de Internet.</p>
            <p>Oblek advierte a sus usuarios que algunos servicios del Sitio podrían incluir, de manera enunciativa más no limitativa, foros de discusión, páginas de Internet personales, avisos clasificados, correos electrónicos, salas de chat, entre otros. Por lo tanto, los Datos Personales que se revelen a través de dichos medios podrían en algunos casos ser accesibles por terceras personas. Oblek no será responsable por el uso, publicación, revelación y/o divulgación que se haga respecto de los Datos Personales usados, publicados, revelados y/o divulgados a través de los medios antes enunciados. Recomendamos a los usuarios ser cuidadosos y responsables sobre toda información de carácter personal que proporcionen en dichos medios.</p>
          </div>
          <div className='text' id='text6' ref={textRef6}>
            <h3>TRANSFERENCIA DE DATOS PERSONALES.</h3>
            <p>Oblek podrá, sin consentimiento del titular, revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales proporcionados por los usuarios, para dar cumplimiento con disposiciones legales que así lo requieran, o bien cuando sea solicitado por autoridades competentes.</p>
            <p>De igual forma podrá revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales a sus empresas filiales, tales como SR & Friends, S.A. de C.V., proveedores, patrocinadores, publicistas, contratistas y/o socios para fines comerciales, como proporcionar datos estadísticos a anunciantes potenciales, enviar publicidad a los usuarios de acuerdo a sus intereses específicos y conducir investigaciones de mercado. Los terceros que reciban la información relacionada con los Datos Personales de los usuarios están obligados a cumplir con lo previsto en este Aviso de Privacidad, así como con los Términos y Condiciones del Sitio y de la legislación aplicable en México. El titular de los Datos Personales podrá otorgar su consentimiento a la transferencia de sus Datos Personales en los términos descritos en el presente Aviso al momento de registrarse al Sitio.</p>
          </div>
          <div className='text' id='text7' ref={textRef7}>
            <h3>CAMBIOS AL AVISO DE PRIVACIDAD.</h3>
            <p>Oblek se reserva el derecho de modificar en cualquier momento el contenido del presente Aviso de Privacidad. Cualquier cambio en el Aviso de Privacidad le será informado a sus usuarios a través del Sitio. Una vez que se publique el Aviso de Privacidad en el Sitio entrará en vigor automáticamente. La fecha de última actualización del presente aviso es el 16 de octubre de 2020.</p>
          </div>
        </div>
      </AvisoCont>
    </Layout>
  )
}

export default Aviso;